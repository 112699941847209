import { Button, Input, Terms, ButtonFlex, AgeRange, Viber } from "./components"
import { motion, useCycle, useDragControls } from "framer-motion"
import { useSuperFan } from "../context"
import React, { useEffect, useMemo, useState } from "react"
import axios from "axios"
import { swearwordsArr } from "./swearwords"
import { useStore } from "../store/index"
import CanvasBtn from "./components/canvasBtn"

export const FormPage = ({ phaserRef, currentPage, setCurrentPage }) => {
  const { trackBtn, submit, info: super_info } = useSuperFan()

  const [info, setInfo] = React.useState({
    NAME: false,
    MOBILE: false,
    countryCode: "+65",
    ageRange: false,
    terms: false,
    viber: false,
  })

  const container = {
    hidden: { opacity: 0, pointerEvents: "none" },
    show: {
      opacity: 1,
      pointerEvents: "auto",
      transition: {
        delay: 1,
      },
    },
  }

  return (
    <motion.div
      style={{
        width: "100%",
        height: "100svh",
        padding: "0 3.5rem",
        position: "absolute",
        top: 0,
        left: 0,
      }}
      variants={container}
      initial="hidden"
      animate={currentPage === 2 ? "show" : "hidden"}>
      <div
        className="block"
        style={{
          height: "25svh",
        }}
      />
      <h4
        style={{
          textAlign: "center",
          color: "white",
          lineHeight: "1.3",
        }}>
        BEFORE WE START,
        <br />
        TELL US ABOUT YOURSELF
      </h4>
      <Input
        size="100%"
        placeholder="NAME"
        onChange={(e) => {
          setInfo({
            ...info,
            NAME: e.target.value,
          })
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Input
          size="20%"
          placeholder="+63"
          type="tel"
          onChange={(e) => {
            setInfo({
              ...info,
              countryCode: e.target.value,
            })
          }}
        />

        <Input
          size="75%"
          placeholder="MOBILE"
          type="tel"
          onChange={(e) => {
            setInfo({
              ...info,
              MOBILE: e.target.value,
            })
          }}
        />
      </div>

      <AgeRange
        onChange={(e) => {
          setInfo({
            ...info,
            ageRange: e.target.value,
          })
        }}
      />
      <div
        className="block"
        style={{
          height: "2svh",
        }}
      />
      <Viber terms={info.viber} setInfo={setInfo} />
      <Terms terms={info.terms} setInfo={setInfo} />
      <div
        className="block"
        style={{
          height: "3svh",
        }}
      />
      <Button
        param="ENTER"
        s
        size="50%"
        id={"formSubmitBTN"}
        onClick={() => {
          if (info.MOBILE.length <= 6) {
            alert("Please enter a valid phone number")
            return
          } else if (info.MOBILE.length > 12) {
            alert("Please enter a valid phone number")
            return
          } else if (!info.terms) {
            alert("Please agree to the terms and conditions")
            return
          } else if (info.NAME === false || info.MOBILE === false || info.ageRange === false) {
            alert("Please fill in the required fields")
            return
          } else {
            phaserRef.current.scene.scenes[2].startGame()
            submit(info)
            trackBtn("formSubmitBTN")
            setCurrentPage(3)
          }

          localStorage.setItem("uid", super_info.uid)
          localStorage.setItem("playAgain", true)
        }}
      />
    </motion.div>
  )
}

export const Instuction = ({ phaserRef, currentPage, setCurrentPage }) => {
  const { trackBtn } = useSuperFan()
  const [trigger, setTrigger] = useState(true)
  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translate(-50%, 0)",
        width: "100vw",
        height: "100svh",
        opacity: currentPage === 3 ? 1 : 0,
        pointerEvents: currentPage === 3 ? "auto" : "none",
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
        transition: "all 0.5s ease",
      }}
      id="instructionBTN"
      onClick={() => {
        if (!trigger) {
          phaserRef.current.scene.scenes[1].startCountDown()
          setCurrentPage(4)
          trackBtn("instructionBTN")
        } else {
          trackBtn("tipsBTN")
          setTrigger(false)
        }
      }}>
      <div
        style={{
          height: "50%",
          background: "#c41f32cf",
          maxWidth: "650px",
          width: "100%",
          zIndex: 100,
          borderTopLeftRadius: "2rem",
          borderTopRightRadius: "2rem",
          position: "relative",
        }}>
        <Instuction1 trigger={trigger} />
        <Instuction2 trigger={!trigger} />
      </div>
    </div>
  )
}

const Instuction1 = ({ trigger }) => {
  return (
    <div
      style={{
        opacity: trigger ? 1 : 0,
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        width: "100%",
      }}>
      <div
        className="block"
        style={{
          height: "3svh",
        }}
      />
      <h3>HOW TO PLAY</h3>
      <div
        className="imgContainer"
        style={{
          height: "18svh",
        }}>
        <img src="/2d/instruction.gif" alt="instruction" />
      </div>

      <p>
        Watch the icon at the top of your screen
        <br /> and tap the ornaments that match it.
        <br /> The more matching ornaments you tap, <br />
        the higher your score, and the more <br />
        time you get to play!
      </p>

      <div
        className="block"
        style={{
          height: "2svh",
        }}
      />
      <p
        style={{
          textDecoration: "underline",
        }}>
        NEXT
      </p>
    </div>
  )
}

const Instuction2 = ({ trigger }) => {
  return (
    <div
      style={{
        opacity: trigger ? 1 : 0,
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        width: "100%",
      }}>
      <div
        className="block"
        style={{
          height: "3svh",
        }}
      />
      <h3>HOW TO PLAY</h3>
      <div
        className="block"
        style={{
          height: "3svh",
        }}
      />
      <div
        style={{
          height: "18svh",
          position: "relative",
        }}>
        <div
          className="imgContainer float"
          style={{
            height: "18svh",
          }}>
          <img src="/2d/power.webp" alt="power" />
        </div>
      </div>

      <div
        className="block"
        style={{
          height: "3svh",
        }}
      />
      <p>
        <span
          style={{
            fontWeight: 700,
          }}>
          Tip
        </span>
        : Tap our Tabby Shine ornaments <br />
        whenever you see them—they’ll super-
        <br />
        boost your score!
      </p>

      <div
        className="block"
        style={{
          height: "2svh",
        }}
      />
      <p
        style={{
          textDecoration: "underline",
        }}>
        TAP ANYWHERE TO START
      </p>
    </div>
  )
}

export const LandingPage = ({ phaserRef, currentPage, setCurrentPage }) => {
  const { trackBtn } = useSuperFan()

  return (
    <motion.div
      style={{
        width: "100%",
        height: "100svh",
        position: "absolute",
        top: 0,
        left: 0,
      }}
      animate={{
        opacity: currentPage === 1 ? 1 : 0,
        pointerEvents: currentPage === 1 ? "auto" : "none",
      }}>
      <div
        className="block"
        style={{
          height: "75svh",
        }}
      />
      <h5
        style={{
          lineHeight: "1.3",
          letterSpacing: "0.04rem",
        }}>
        Play our festive holiday game
        <br />
        for a chance to win a prize!
      </h5>
      <div
        className="block"
        style={{
          height: "2svh",
        }}
      />
      <Button
        param="GET STARTED"
        id="startBTN"
        size="50%"
        onClick={() => {
          trackBtn("startBTN")
          phaserRef.current.scene.scenes[2].startYellowBall()

          if (localStorage.getItem("playAgain") === "true") {
            setTimeout(() => {
              setCurrentPage(3)
              phaserRef.current.scene.scenes[2].startGame()
            }, 800)
          } else {
            setCurrentPage(2)
          }
        }}
      />
    </motion.div>
  )
}

export const Lower2000 = ({ phaserRef, currentPage, setCurrentPage }) => {
  const { trackBtn, info } = useSuperFan()

  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translate(-50%, 0)",
        width: "100vw",
        height: "100svh",
        opacity: currentPage === 5 ? 1 : 0,
        pointerEvents: currentPage === 5 ? "auto" : "none",
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
        transition: "all 0.5s ease",
      }}>
      <div
        style={{
          height: "40%",
          background: "#c41f32cf",
          width: "100%",
          zIndex: 100,
          borderTopLeftRadius: "0.5rem",
          borderTopRightRadius: "0.5rem",
          maxWidth: "600px",
        }}>
        <div
          className="block"
          style={{
            height: "8svh",
          }}
        />
        <h4
          style={{
            lineHeight: "1.3",
          }}>
          Oops! <br />
          You didn’t reach a winning score of 2000
        </h4>

        <div
          className="block"
          style={{
            height: "5svh",
          }}
        />

        <Button
          param="TRY AGAIN"
          size="50%"
          id={"tryAgainBTN"}
          onClick={() => {
            localStorage.setItem("playAgain", true)
            localStorage.setItem("uid", info.uid)
            trackBtn("tryAgainBTN")
            window.location.reload()
          }}
        />
      </div>
    </div>
  )
}

export const Bigger2000 = ({ phaserRef, currentPage, setCurrentPage }) => {
  const [name, setName] = React.useState(false)
  const { trackBtn, customType, submit } = useSuperFan()
  const [profanity, setProfanity] = React.useState(false)

  const { setShareImgUrl } = useStore((state) => ({
    setShareImgUrl: state.setShareImgUrl,
  }))

  return (
    <div
      className="page6"
      style={{
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translate(-50%, 0)",
        width: "100vw",
        height: "100svh",
        opacity: currentPage === 6 ? 1 : 0,
        pointerEvents: currentPage === 6 ? "auto" : "none",
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "end",
        transition: "all 0.5s ease",
      }}>
      <div
        style={{
          height: "45%",
          background: "#c41f32c3",
          width: "100%",
          maxWidth: "600px",
          zIndex: 100,
          borderTopLeftRadius: "0.5rem",
          borderTopRightRadius: "0.5rem",
        }}>
        <div
          className="block"
          style={{
            height: "4svh",
          }}
        />
        <h4
          style={{
            lineHeight: "1.2",
          }}>
          You did it!
          <br />
          Personalize your score and
          <br />
          share it with your friends.
        </h4>

        <div
          className="block"
          style={{
            height: "2svh",
          }}
        />
        <div
          style={{
            height: "10svh",
            position: "relative",
          }}>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}>
            {name.map((item, index) => {
              return <Font key={index} font={item} />
            })}
          </div> */}
          <input
            type="text"
            placeholder="YOUR NAME"
            onChange={(e) => {
              // limit to 10
              if (e.target.value.length > 10) {
                e.target.value = e.target.value.slice(0, 10)
              }
              const value = e.target.value
              setName(value)
            }}
            style={{
              position: "absolute",
              left: "50%",
              top: 0,
              transform: "translate(-50%, 0)",
              width: "90%",
              height: "100%",
              zIndex: 100,
              opacity: 1,
              backgroundColor: "transparent",
              border: "none",
              letterSpacing: "0.8rem",
              textAlign: "center",
              color: "white",
              fontSize: "2rem",
              borderBottom: "0.15rem solid #ffffff80",
            }}
          />
        </div>
        <div
          className="block"
          style={{
            height: "3svh",
          }}
        />
        <h5
          style={{
            color: "#f7920d",
            opacity: profanity ? 1 : 0,
          }}
          id="profanity">
          No profanity, please. Try Again!
        </h5>

        <Button
          param="NEXT"
          size="40%"
          id={"AfterGameNextBTN2"}
          onClick={() => {
            // https://coach-holiday-leaderbroad-aqnp.onrender.com/getToxicity?text=akn
            if (name === false || name === "") {
              alert("Please enter your name")
              return
            } else {
              if (!swearwordsArr.includes(name.toLowerCase())) {
                console.log("no profanity")
                let _nameFist = name[0].toUpperCase()
                let _nameLast = name.slice(1).toLowerCase()

                let _name = _nameFist + _nameLast

                phaserRef.current.scene.scenes[3]._name = _name
                phaserRef.current.scene.scenes[3]._score = phaserRef.current.scene.scenes[1].pointText.text
                phaserRef.current.scene.scenes[0].startScene("LastPage")
                trackBtn("AfterGameNextBTN2")
                customType("name", name)
                submit({ leaderBroadName: name })

                axios
                  .get("https://coach-holiday-leaderbroad-aqnp.onrender.com/getimg", {
                    params: {
                      name: name,
                      score: phaserRef.current.scene.scenes[1].pointText.text,
                      date: Date().split("(")[0],
                      contry: Date().split("(")[1].split(")")[0],
                    },
                  })
                  .then((res) => {
                    setShareImgUrl(res.data)
                  })
                setCurrentPage(7)
              } else {
                setProfanity(true)
              }
            }
          }}
        />
      </div>
    </div>
  )
}

export const LastPage = ({ phaserRef, currentPage, setCurrentPage }) => {
  const { trackBtn } = useSuperFan()
  const [modal, setModal] = useState(false)
  const { shareImgUrl } = useStore((state) => ({
    shareImgUrl: state.shareImgUrl,
  }))

  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translate(-50%, 0)",
        width: "100vw",
        height: "100svh",
        opacity: currentPage === 7 ? 1 : 0,
        pointerEvents: currentPage === 7 ? "auto" : "none",
        transition: "all 0.5s ease",
        overflowY: "scroll",
        maxWidth: "700px",
        overflow: "hidden",
      }}>
      <PopUp modal={modal} setModal={setModal} />
      <div>
        <div
          className="block"
          style={{
            height: "7.5svh",
          }}
        />

        <div
          style={{
            height: "8svh",
            position: "relative",
            width: "fit-content",
            margin: "0 auto",
          }}>
          <h4
            style={{
              fontFamily: "HelveticaLTPro-Light",
              fontSize: window.innerWidth > 721 ? "1.5rem" : "0.9rem",
            }}>
            Tap the gift box to
            <br />
            redeem your prize!
            <motion.div
              // hover effect
              whileHover={{
                scale: 1.1,
                transition: {
                  duration: 0.5,
                },
              }}
              onClick={() => {
                setModal(true)
              }}
              className="imgContainer"
              id="redeem"
              style={{
                height: "6svh",
                position: "absolute",
                top: "-5%",
                left: "110%",
                zIndex: 1,
              }}>
              <img src="/2d/gift.webp" alt="gift" />
            </motion.div>
          </h4>
        </div>
        <div
          className="rankContainer"
          style={{
            width: "70%",
            height: "47svh",
            margin: "0 auto",
            boxShadow: "0 0 1rem #1a1919",
            position: "relative",
          }}
          onClick={async () => {
            let url = shareImgUrl
            var download = document.createElement("a")
            download.download = "CoachHoliday.jpg"
            var bolb = await fetch(url).then((r) => r.blob())
            download.href = URL.createObjectURL(bolb)
            download.click()
          }}>
          <div
            className="imgContainer-full"
            style={{
              height: "100%",
              width: "100%",
              opacity: 0,
            }}>
            <img src={shareImgUrl} alt="shareimg" />
          </div>
          {/* <div
            className="imgContainer"
            style={{
              height: "6svh",
              position: "absolute",
              right: 0,
              transform: "translate(30% , 70%)",
              bottom: "0",
            }}>
            <img src="/2d/download.png" />
          </div> */}

          <div
            className="imgContainer"
            style={{
              height: "6svh",
              position: "absolute",
              left: 0,
              transform: "translate(-5% , -10%)",
              top: "0",
              rotate: "180deg",
            }}>
            <img src="/2d/light.webp" alt="light" />
          </div>

          <div
            className="imgContainer"
            style={{
              height: "6.5svh",
              position: "absolute",
              left: "80%",
              transform: "translate(-50% , 0%)",
              bottom: "0",
            }}>
            <img src="/2d/logolanding.webp" alt="logolanding" />
          </div>
        </div>
        <div
          className="block"
          style={{
            height: "6svh",
          }}
        />
        <Button
          size="45%"
          param="SHARE"
          id={"SHARE"}
          onClick={() => {
            trackBtn("SHARE")

            let data = {
              title: "Coach Holiday Game",
              text: "I just scored " + phaserRef.current.scene.scenes[1].pointText.text + " points in the Coach Holiday Game! Can you beat my score?",
              url: window.location.href,
            }

            if (navigator.canShare(data)) {
              navigator.share(data).then(() => {
                console.log("Successfully sent share")
              })
            }
          }}
        />
        <Button
          size="45%"
          param="LEADERBOARD"
          id={"LEADERBOARD"}
          onClick={() => {
            trackBtn("LEADERBOARD")
            let phasercontainer = document.getElementById("phaser-container")
            let THREECANVAS = document.getElementById("THREECANVAS")
            phasercontainer.style.opacity = phasercontainer.style.opacity === "0" ? "1" : "0"
            THREECANVAS.style.opacity = THREECANVAS.style.opacity === "0" ? "1" : "0"

            setCurrentPage(8)
          }}
        />

        <div
          className="block"
          style={{
            height: "2svh",
          }}
        />

        <div
          id="shopHoliday"
          onClick={() => {
            trackBtn("SHOP HOLIDAY")
            window.open("https://www.trunc.ph/default/coach", "_blank")
          }}>
          <h5
            style={{
              textAlign: "center",
              textDecoration: "underline",
              fontFamily: "HelveticaLTPro-Light",
              fontWeight: 100,
            }}>
            SHOP HOLIDAY
          </h5>
        </div>
      </div>
    </div>
  )
}

const PopUp = ({ modal, setModal }) => {
  const { submit, isRedeem, customType } = useSuperFan()
  const [index, setIndex] = useState(1)
  const popRef = React.useRef(null)
  useMemo(() => {
    if (index >= 3 && !isRedeem) {
      submit({ redeemTime: Date() })
      customType("redeemTime", Date())
    }
    if (isRedeem) {
      setIndex(3)
    }
  }, [index, isRedeem])

  const { shareImgUrl } = useStore((state) => ({
    shareImgUrl: state.shareImgUrl,
  }))

  return (
    <div
      ref={popRef}
      style={{
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, 0)",
        width: window.innerWidth > 721 ? 400 : window.innerWidth * 0.77,
        height: window.innerWidth > 721 ? (400 * 16) / 9 : (window.innerWidth * 0.77 * 16) / 9,
        pointerEvents: true ? "auto" : "none",
        overflowY: "scroll",
        maxWidth: "700px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        boxShadow: "0 0 1rem #1a1919",
        backgroundImage: `url(${shareImgUrl})`,
        backgroundSize: "cover",
        zIndex: modal ? 100 : -100,
        overflow: "hidden",
        opacity: modal ? 1 : 0,
        pointerEvents: modal ? "auto" : "none",
      }}>
      <div
        className="block"
        style={{
          height: window.innerWidth > 721 ? (400 * 16 * 0.68) / 9 : ((window.innerWidth * 0.77 * 16) / 9) * 0.65,
        }}
      />

      <div
        style={{
          width: "3rem",
          height: "3rem",
          position: "absolute",
          backgroundPosition: "center",
          top: "0",
          right: "0",
          zIndex: 100,
          padding: "0.5rem",
        }}
        className="imgContainer"
        onClick={() => {
          setModal(false)
        }}>
        <img src="/2d/closebtn.webp" alt="closebtn" />
      </div>

      <div
        className="block"
        style={{
          height: window.innerWidth > 721 ? (400 * 16 * 0.03) / 9 : ((window.innerWidth * 0.77 * 16) / 9) * 0.03,
        }}
      />

      {index >= 3 ? (
        <p
          style={{
            fontSize: "0.65rem",
          }}>
          Thanks for playing!
          <br />
          <span
            style={{
              opacity: 0,
            }}>
            Present it to our staff for your reward!
          </span>
          <br />
        </p>
      ) : (
        <p
          style={{
            fontSize: "0.7rem",
          }}>
          Do not tap the button below.
          <br />
          Present it to our staff for your reward!
          <br />
        </p>
      )}

      <div
        className="block"
        style={{
          height: window.innerWidth > 721 ? (400 * 16 * 0.03) / 9 : ((window.innerWidth * 0.77 * 16) / 9) * 0.02,
        }}
      />
      <motion.div
        // hover effect
        whileHover={{
          scale: 1.1,
          transition: {
            duration: 0.5,
          },
        }}
        onClick={() => {
          setIndex(index + 1)
        }}
        style={{
          width: "50%",
          height: window.innerWidth > 721 ? (400 * 16 * 0.08) / 9 : (window.innerWidth * 0.77 * 16 * 0.075) / 9,
          margin: "0 auto",
          borderRadius: "0.4rem",
          backgroundColor: index === 1 ? "#1eaf33" : index === 2 ? "#f7920d" : "#bab9b8",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "0.1rem solid white",
          paddingTop: "0.3svh",
        }}>
        <h6
          style={{
            fontFamily: "HelveticaLTPro-Light",
            letterSpacing: "0.08rem",
            fontWeight: 100,
            color: "white",
          }}>
          {index === 1 ? "STAFF REDEEM" : index === 2 ? "CONFIRM" : "REDEEMED"}
        </h6>
      </motion.div>
    </div>
  )
}
export const LeaderBroad = ({ phaserRef, currentPage, setCurrentPage }) => {
  const { trackBtn, info, collection } = useSuperFan()
  const [leaderBroad, setLeaderBroad] = React.useState([])
  const getLeaderBroad = async () => {
    axios
      .get("https://coach-holiday-leaderbroad-aqnp.onrender.com/getLeaderBroad", {
        params: {
          collection: collection,
        },
      })
      .then((res) => {
        // arr only want first 30
        setLeaderBroad(res.data.slice(0, 30))
      })
  }

  useEffect(() => {
    if (currentPage === 8) {
      getLeaderBroad()
    }
  }, [currentPage])

  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translate(-50%, 0)",
        width: "100vw",
        height: "100svh",
        opacity: currentPage === 8 ? 1 : 0,
        pointerEvents: currentPage === 8 ? "auto" : "none",
        transition: "all 0.5s ease",
        overflowY: "scroll",
        maxWidth: "700px",
      }}>
      <div>
        <div
          className="block"
          style={{
            height: "2svh",
          }}
        />

        <div
          className="imgContainer"
          style={{
            height: "11vh",
          }}>
          <img src="/2d/logolanding.webp" alt="logoloanding" />
        </div>

        <div
          className="block"
          style={{
            height: "1svh",
          }}
        />

        <div
          style={{
            height: "8vh",
          }}>
          <h4
            style={{
              fontFamily: "HelveticaLTPro-Light",
            }}>
            Congratulations! <br />
            You’re one of the top players
            <br />
            in your area.
          </h4>
        </div>
        <div
          className="block"
          style={{
            height: "3.2svh",
          }}
        />

        <div
          className="rankContainer"
          style={{
            width: "75%",
            height: "47svh",
            margin: "0 auto",
            overflowY: "scroll",
            padding: "0 0.5rem",
          }}>
          {leaderBroad.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "2.5svh 0",
                }}>
                <h4>{index + 1}</h4>
                <h4>{item.name}</h4>
                <h4>{item.score}</h4>
              </div>
            )
          })}
        </div>
        <div
          className="block"
          style={{
            height: "3.2svh",
          }}
        />
        <Button
          size="45%"
          param="BACK"
          id={"BACKBTN"}
          onClick={() => {
            trackBtn("BACKBTN")
            let phasercontainer = document.getElementById("phaser-container")
            let THREECANVAS = document.getElementById("THREECANVAS")
            phasercontainer.style.opacity = phasercontainer.style.opacity === "0" ? "1" : "0"
            THREECANVAS.style.opacity = THREECANVAS.style.opacity === "0" ? "1" : "0"

            setCurrentPage(7)
          }}
        />
        <Button
          size="45%"
          param="PLAY AGAIN"
          id={"PLAYAGAIN"}
          onClick={() => {
            trackBtn("learderBroadPLAYAGAIN")
            localStorage.setItem("playAgain", true)
            localStorage.setItem("uid", info.uid)
            window.location.reload()
          }}
        />
      </div>
    </div>
  )
}
